<template>
  <AView
    :value="value"
    :active="lead.id === activeLeadId"
    :name="lead.name"
    ref="lead-editor-view"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
  >
    <template v-slot:content="{}">
      <v-card class="transparent mt-4 pa-2 elevation-0">
        <v-card-text>
          <LeadFilesEditor
            ref="form"
            v-model="lead"
            :uploadURL="getUploadUrl()"
            :uploadHeaders="uploadHeaders"
            @onAfterUpload="onAfterUpload"
            @onFileDelete="onFileDelete"
          ></LeadFilesEditor>
        </v-card-text>
      </v-card>
    </template>
  </AView>
</template>
          
          
      <script>
import { mapGetters, mapState } from "vuex";
import { LeadsAPIInstance } from "../../../../../components/api";
import LeadFilesEditor from "../../../../../components/wad/organisms/leads/forms/editor/LeadFilesEditorForm.vue";
import AView from "../../../../../components/wad/organisms/layout/A-View.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    //   FormSection,
    LeadFilesEditor,
  },
  data() {
    return {
      updatedTimeout: undefined,

      lead: {
        searchableKeyword: {},
      },

      uploadHeaders: LeadsAPIInstance.uploadHeaders,
    };
  },
  computed: {
    ...mapState("LeadStore", ["activeLeadId", "displayedLeads"]),
    ...mapGetters("LeadStore", ["leadById"]),
  },
  created() {
    this.$store.dispatch("LeadStore/GetLead", {
      id: this.value.relation.params.leadId,
    });

    this.lead = this.leadById(this.value.relation.params.leadId);
  },
  methods: {
    onAfterUpload() {
      this.$store.dispatch("LeadStore/GetLead", {
        id: this.value.relation.params.leadId,
        refresh: true,
      });
    },
    onFileDelete(file) {
      this.$store.dispatch("LeadStore/DeleteLeadAttachment", {
        lead: this.lead,
        file,
      });
    },
    getUploadUrl() {
      return LeadsAPIInstance.getUploadUrl(this.lead);
    },
    onHover() {
      this.$store.commit(
        "LeadStore/setActiveId",
        this.value.relation.params.leadId
      );
    },
  },

  watch: {
    displayedLeads: {
      async handler(newVal) {
        if (newVal) {
          this.lead = this.leadById(this.value.relation.params.leadId);
        }
      },
      deep: true,
    },
  },
};
</script>