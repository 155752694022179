<template>
  <v-row class="pa-0 ma-0">
    <v-col cols="12" class="pa-0 ma-0">
      <FormSection
        :label="'Lead Attachments'"
        class="mt-0"
        :icon="'mdi-link-variant'"
        :editable="false"
        underline
        right
      ></FormSection>
      <!-- <v-alert type="warning" class="my-2" outlined>
        These documents may be used as an examples and may be shared with a
        customer. Please make sure that all document presented here contains
        watermarks and doesn't contains any project-related info.
      </v-alert> -->
      <VueFileAgent
        deletable
        :linkable="true"
        :crossOrigin="'use-credentials'"
        :editable="false"
        v-model="attachments"
        :uploadUrl="uploadURL"
        :uploadHeaders="uploadHeaders"
        @upload="() => $emit('onAfterUpload')"
        @delete="(...props) => $emit('onFileDelete', ...props)"
      ></VueFileAgent>
    </v-col>
  </v-row>
</template>
        
        
<script>
import FormSection from "../../../../atoms/common/FormSection.vue";

export default {
  props: {
    value: {},
    uploadURL: {
      type: String,
      default: "",
    },
    uploadHeaders: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const { attachments } = this.prepareFiles(this.value);

    return {
      // Form
      pValid: this.valid,

      attachments,
    };
  },
  components: {
    FormSection,
  },
  methods: {
    prepareFiles(value) {
      let attachments = value.privateMaterials || [];

      if (value.attachments && value.attachments.length) {
        attachments = value.attachments
          .map((doc) => doc.document);
      }

      //   customerDocuments = value.materials
      //     .filter((doc) => doc.type === "CUSTOMER")
      //     .map((doc) => doc.document);

      //   publicDocuments = value.materials
      //     .filter((doc) => doc.type === "PUBLIC")
      //     .map((doc) => doc.image);
      // }

      return { attachments };
    },
  },
  watch: {
    value: {
      handler(newVal) {
        const { attachments } = this.prepareFiles(newVal);

        this.attachments = attachments;
      },
      deep: true,
    },

    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
        
        
<style lang="scss" scoped>
.term-col {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>